.custom-quill-editor {
    background-color: #FFF;
    /* Fondo blanco */
    height: 400px;
    /* Altura total del componente */
    width: 100%;
    /* Ancho ajustable */
}

.custom-quill-editor .ql-container {
    height: calc(100% - 42px);
    /* Ajusta el contenedor, restando la altura de la toolbar */
}

.custom-quill-editor .ql-editor {
    height: 100%;
    /* Asegura que el editor use todo el espacio */
    padding-bottom: 0;
    /* Elimina espacio extra en la parte inferior */
}

.custom-quill-editor .ql-toolbar {
    border-bottom: 1px solid #ccc;
    /* Borde opcional para la toolbar */
}