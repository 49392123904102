@import url('https://fonts.googleapis.com/css2?family=Bakbak+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Days+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Francois+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;500;600;700&display=swap');
@import url("https://fonts.googleapis.com/css?family=Fira+Code&display=swap");

@font-face{
	font-family: title-fenabo-font;
	src: url(assets/fonts/X-Heighting.otf);
}

html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
